module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-apollo/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://admin.zaunergroup.com/nachhaltigkeit/graphql"},
    },{
      plugin: require('../node_modules/@perituswebdesign/gatsby-plugin-matomo-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"dataLayerName":"_mtm","domain":"cdn.matomo.cloud/zaunergroup.matomo.cloud","containerId":"CM727TXg","includeInDevelopment":false,"defaultDataLayer":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Zauner Anlagentechnik GmbH","short_name":"zaunergroup","lang":"de","start_url":"/","display":"standalone","icon":"src/images/favicon-nachhaltigkeit.png","legacy":false,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"67a2239c022824b236d5ef08c9039281"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
